import React from "react";
import hero from "../../static/video/hero.mp4";

const Hero = () => {
  return (
    <section className="hero w-screen relative h-96 lg:h-[55em] max-h-[60vh] overflow-y-hidden">
      <div className="bg-black/20 absolute inset-0 z-10"></div>
      <div className="bg-gradient-to-t from-light dark:from-dark via-transparent to-transparent opacity-100 absolute inset-0 pointer-events-none z-10"></div>
      <div className="bg-gradient-to-b from-dark dark:from-dark via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none z-10"></div>
      <video
        className="absolute inset-0 w-full h-full object-cover object-center"
        muted
        loop
        autoPlay
      >
        <source src={hero} type="video/mp4" />
      </video>
    </section>
  );
};

export default Hero;
