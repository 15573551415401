import React from "react";
import Section from "../common/Section";
import SectionHeading from "../common/SectionHeading";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const TrustedBy = () => {
  const { t } = useI18next();
  return (
    <Section className="flex flex-col gap-8 md:gap-12">
      <SectionHeading center>{t("general.trustedBy")}</SectionHeading>
      <p className="text-left lg:text-center text-text-primary dark:text-text-primary-dark">
        {t("index.sponsors")}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 sm:content-between items-center bg-white dark:bg-light rounded-lg gap-8 p-6">
        <a
          href="https://www.st.com/content/st_com/en.html"
          target="_blank"
          rel="external nofollow"
          className="flex justify-center items-center h-full transition-transform transform hover:scale-105 max-h-16"
        >
          <StaticImage
            className="h-full"
            objectFit="contain"
            src="../../static/svg/st.svg"
            alt="STMicroelectronics"
          />
        </a>

        <a
          href="https://bcast.pl/"
          target="_blank"
          rel="external nofollow"
          className="flex justify-center items-center h-full transition-transform transform hover:scale-105 max-h-16"
        >
          <StaticImage
            className="h-full"
            objectFit="contain"
            src="../../static/images/bcast.png"
            alt="BCast"
          />
        </a>

        <a
          href="https://www.erzeszow.pl/"
          target="_blank"
          rel="external nofollow"
          className="flex justify-center items-center h-full transition-transform transform hover:scale-105 max-h-16"
        >
          <StaticImage
            className="h-full"
            objectFit="contain"
            src="../../static/svg/rzeszow.svg"
            alt="Rzeszów - Capital of innovation"
          />
        </a>
      </div>
    </Section>
  );
};

export default TrustedBy;
