import React from "react";
import Section from "../common/Section";
import SectionHeading from "../common/SectionHeading";
import { Link } from "gatsby";
import Button from "../common/Button";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";

const Careers = () => {
  const { t } = useI18next();

  return (
    <Section className="flex flex-col-reverse md:flex-row items-center justify-between gap-12">
      <div className="md:w-1/2">
        <StaticImage
          objectFit="contain"
          className="rounded-lg"
          src="../../static/images/06.jpg"
          alt="Team putting their hands together on top of the other"
        />
      </div>
      <div className="flex flex-col gap-6 md:w-1/2">
        <SectionHeading caption={t("general.joinUs")}>
          {t("general.careers")}
        </SectionHeading>
        <p className="prose text-text-primary dark:text-text-primary-dark">
          {t("index.careers")}
        </p>
        <div className="flex flex-wrap gap-6">
          <Button as={Link} to="#">
            {t("general.meetTeam")}
          </Button>
          <Link
            to="#"
            className="flex items-center gap-2 text-text-primary dark:text-text-primary-dark hover:brightness-110 transition-all"
          >
            <svg
              ariaHidden="true"
              focusable="false"
              className="h-5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              ></path>
            </svg>
            <span className="font-bold w-max before:block before:h-[2px] before:absolute before:w-full before:bg-primary before:bottom-0 before:left-0 relative">
              {t("general.followLinkedIn")}
            </span>
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default Careers;
