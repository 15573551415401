import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";

const PostItem = ({ children, heading, date, to }) => {
  const { t } = useI18next();

  return (
    <a
      href={to}
      rel="external"
      target="_blank"
      className="group h-64 md:h-96 flex items-end overflow-hidden rounded-lg shadow-lg relative group"
    >
      {/* <StaticImage
        src="../../../static/images/04.jpg"
        alt="City view"
        className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
      /> */}
      {children}

      <div className="bg-gradient-to-t from-dark dark:from-gray-900 to-transparent opacity-100 absolute inset-0 pointer-events-none"></div>

      <div className="inline-block text-white text-sm md:text-lg relative mx-4 md:ml-5 mb-3 lg:absolute lg:left-0 lg:bottom-0 lg:transition-transform lg:transform lg:translate-y-10 lg:group-hover:translate-y-0">
        {date && <small className="text-light text-sm">{date}</small>}
        <h3 className="font-bold text-white text-xl mt-2 mb-6">{heading}</h3>
        <span className="text-primary text-base">{t("general.readMore")}</span>
      </div>
    </a>
  );
};

export default PostItem;
