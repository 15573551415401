import React from "react";
import SectionHeading from "../common/SectionHeading";
import Button from "../common/Button";
import Section from "../common/Section";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Link as Scroll } from "react-scroll";

const About = () => {
  const { t } = useI18next();

  return (
    <Section
      id="about"
      className="flex flex-col md:flex-row items-center justify-between gap-12"
    >
      <div className="flex flex-col gap-6 md:w-1/2">
        <SectionHeading as="h1" caption="about">
          Connected Life
        </SectionHeading>
        <p className="prose text-text-primary dark:text-text-primary-dark">
          {t("index.hero1")}
        </p>
        <div className="flex gap-6">
          <Button
            as={Scroll}
            to="products"
            spy={true}
            smooth={true}
            duration={500}
          >
            {t("general.getStarted")}
          </Button>
          <Button as={Link} to="/about" outline>
            {t("general.learnMore")}
          </Button>
        </div>
      </div>
      <div className="md:w-1/2">
        <StaticImage
          className="rounded-lg"
          src="../../static/images/about1.jpg"
          alt="Person checking on plants"
        />
      </div>
    </Section>
  );
};

export default About;
