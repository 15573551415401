import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Products from "../components/Products";
import TrustedBy from "../components/TrustedBy";
import Careers from "../components/Careers";
import News from "../components/News";
import Cta from "../components/Cta";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layouts";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <body className="is-index-page" />
      </Helmet>
      <Hero />
      <Fade bottom>
        <About />
      </Fade>
      <Fade bottom>
        <Products />
      </Fade>
      <Fade bottom>
        <TrustedBy />
      </Fade>
      <Fade bottom>
        <Careers />
      </Fade>
      <Fade bottom>
        <News />
      </Fade>
      {/* <Cta /> */}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
