import React from "react";
import SectionHeading from "../common/SectionHeading";
import Section from "../common/Section";
import ProductsGrid from "./components/ProductsGrid";
import { useI18next } from "gatsby-plugin-react-i18next";

const Products = () => {
  const { t } = useI18next();

  return (
    <div
      id="products"
      className="bg-gradient-to-t from-light via-gray-200 to-light dark:from-dark dark:via-gray-700 dark:to-dark relative"
    >
      <Section className="flex flex-col gap-6">
        <SectionHeading caption={t("general.ourOther")} center>
          {t("general.products")}
        </SectionHeading>
        <p className="text-left lg:text-justify text-text-primary dark:text-text-primary-dark">
          {t("index.products1")}
        </p>
        <ProductsGrid />
      </Section>
    </div>
  );
};

export default Products;
