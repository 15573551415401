import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

export const LangLink = ({ to, children, ...props }) => {
  const { language } = useI18next();

  return (
    <Link to={`/${language}${to}`} {...props}>
      {children}
    </Link>
  );
};
