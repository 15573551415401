import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { LangLink as Link } from "../../common/LangLink";
import Ca from "../../../static/svg/ConnectedAgro.inline.svg";
import Cc from "../../../static/svg/ConnectedCity.inline.svg";
import Cp from "../../../static/svg/ConnectedProperty.inline.svg";
import Ce from "../../../static/svg/ConnectedEnvironment.inline.svg";

const ProductsGrid = () => {
  return (
    <div className="flex flex-col lg:grid grid-cols-6 gap-8 grid-rows-4 product-grid">
      <div className="col-span-4 row-span-3">
        <Link
          to="/project"
          className="group flex items-end rounded-lg overflow-hidden shadow-lg relative p-4 h-full"
        >
          <div className="bg-black bg-opacity-20 absolute inset-0 pointer-events-none z-10"></div>
          <StaticImage
            objectFit="cover"
            src="../../../static/images/05.jpg"
            className="product-tile w-full h-full object-cover object-center absolute inset-0 transform group-hover:scale-105 transition duration-200"
          />
          <div className="w-full h-fit flex flex-col bg-white dark:bg-light text-center rounded-lg relative p-4 md:mx-10 z-10">
            <div className="object-contain h-10 flex items-center justify-center">
              <Ca />
            </div>
          </div>
        </Link>
      </div>
      <div className="col-span-2 row-span-3">
        <a
          target="_blank"
          href="https://connectedcity.pl"
          rel="external"
          className="group flex items-end rounded-lg overflow-hidden shadow-lg relative p-4 h-full"
        >
          <div className="bg-black bg-opacity-20 absolute inset-0 pointer-events-none z-10"></div>
          <StaticImage
            objectFit="cover"
            src="../../../static/images/04.jpg"
            className="product-tile w-full h-full object-cover object-center absolute inset-0 transform group-hover:scale-105 transition duration-200"
          />
          <div className="w-full h-fit flex flex-col bg-white dark:bg-light text-center rounded-lg relative p-4 md:mx-10 z-10">
            <div className="object-contain h-10 flex items-center justify-center">
              <Cc />
            </div>
          </div>
        </a>
      </div>
      <div className="col-span-3">
        <Link
          to="/products/connectedproperty"
          className="group flex items-end rounded-lg overflow-hidden shadow-lg relative p-4 h-full"
        >
          <div className="bg-black bg-opacity-20 absolute inset-0 pointer-events-none z-10"></div>
          <StaticImage
            objectFit="cover"
            src="../../../static/images/02.jpg"
            className="product-tile w-full h-full object-cover object-center absolute inset-0 transform group-hover:scale-105 transition duration-200"
          />
          <div className="w-full h-fit flex flex-col bg-white dark:bg-light text-center rounded-lg relative p-4 md:mx-10 z-10">
            <div className="object-contain h-10 flex items-center justify-center">
              <Cp />
            </div>
          </div>
        </Link>
      </div>
      <div className="col-span-3">
        <Link
          to="/products/connectedenvironment"
          className="group flex items-end rounded-lg overflow-hidden shadow-lg relative p-4 h-full"
        >
          <div className="bg-black bg-opacity-20 absolute inset-0 pointer-events-none z-10"></div>
          <StaticImage
            objectFit="cover"
            src="../../../static/images/03.jpg"
            className="product-tile w-full h-full object-cover object-center absolute inset-0 transform group-hover:scale-105 transition duration-200"
          />
          <div className="w-full h-fit flex flex-col bg-white dark:bg-light text-center rounded-lg relative p-4 md:mx-10 z-10">
            <div className="object-contain h-10 flex items-center justify-center">
              <Ce />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductsGrid;
