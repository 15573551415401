import React from "react";
import SectionHeading from "../common/SectionHeading";
import Section from "../common/Section";
import PostItem from "./components/PostItem";
import { useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

const News = () => {
  const { t } = useI18next();
  return (
    <Section className="flex flex-col gap-8">
      <div className="flex flex-col gap-8 md:flex-row md:justify-between md:items-end">
        <SectionHeading caption={t("general.stayUpdated")}>
          {t("general.news")}
        </SectionHeading>
        {/* <Link
          to="#"
          className="group transition-colors hover:brightness-110 text-sm md:text-base flex items-center gap-2 font-bold w-max text-text-primary dark:text-text-primary-dark before:block before:h-[2px] before:absolute before:w-full before:bg-primary before:bottom-0 before:left-0 relative"
        >
          {t("general.viewOlder")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 transition-transform transform group-hover:translate-x-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </Link> */}
      </div>
      <p className="mt-2 text-left text-text-primary dark:text-text-primary-dark">
        {t("index.news")}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 xl:gap-8 mb-4 md:mb-8">
        <PostItem
          to="https://mvno-gsm.pl/rodzimy-operator-uczyni-polskie-miasta-bardziej-smart/"
          heading={`Rodzimy operator uczyni polskie miasta bardziej "smart"`}
        >
          <StaticImage
            src="../../static/images/news/BCAST-Rodzimy-operator-uczyni-polskie-miasta-bardziej-smart.webp"
            alt="City view"
            className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
          />
        </PostItem>
        <PostItem
          to="https://naukawpolsce.pl/aktualnosci/news%2C30525%2Cpolskie-urzadzenia-zbadaja-grubosc-pokrywy-snieznej-na-lodowcach.html"
          heading={`Polskie urządzenia zbadają grubość pokrywy śnieżnej na lodowcach`}
        >
          <StaticImage
            src="../../static/images/news/31030525_31030506.jpg"
            alt="Iceberg"
            className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
          />
        </PostItem>
        <PostItem
          to="https://mitsmr.pl/b/iiot---czas-na-inteligentne-fabryki/PwEYY433E"
          heading={`IIoT – czas na inteligentne fabryki`}
        >
          <StaticImage
            src="../../static/images/news/cover.jpg"
            alt="Industry 4.0 image"
            className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
          />
        </PostItem>
        <PostItem
          to="https://www.magazynit.pl/artykuly-it-solutions/28726-industrial-iot-rewolucja-przeoczona-przez-polski-biznes.html"
          heading={`Industrial IoT – rewolucja przeoczona przez polski biznes?`}
        >
          <StaticImage
            src="../../static/images/news/Industrial_IoT__rewolucja_przeoczona_przez_polski_biznes.jpg"
            alt="Industry 4.0 image"
            className="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
          />
        </PostItem>
      </div>
    </Section>
  );
};

export default News;
